/*
 * @Author: fernandou
 * @Date:   2016-07-29 16:23
 */

(function(root, factory) {
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = factory();
    } else if (typeof define === 'function' && define.amd) {
        define(factory);
    } else {
        root.Promise = root.Promise || factory.call(root);
    }
})(this, function() {
    'use strict';

    function Promise(resolver) {
        this.status = 'pending';
        this.value;
        this.reason;

        // 回调函数收集 只有当状态为pending时才会收集，
        // 相当于事件订阅，
        this._resolves = [];
        this._rejects = [];

        //状态改变的时机
        if (isFn(resolver)) {
            resolver(this.resolve, this.reject);
        }
    };


    //事件已经促发的情况下立即执行
    Promise.prototype.then = function(resolve, reject) {
        //每次返回一个不同的promise;
        var next = this._next || (this._next = new Promise());
        var status = this.status;
        var result;

        switch (status) {
            case 'pending':
                isFn(resolve) && this._resolves.push(resolve);
                isFn(reject) && this._rejects.push(reject);
                break;

            case 'resolved':
                try {
                    result = resolve(this.value);
                    resolveRelation(next, result);
                } catch (e) {
                    next.reject(e);
                }
                break;

            case 'rejected':
                try {
                    result = reject(this.reason);
                    resolveRelation(next, result);
                } catch (e) {
                    next.reject(e);
                }
                break;
        }
        return next;
    };


    //促发接受事件
    Promise.prototype.resolve = function(value) {
        if ('rejected' === this.status) throw Error('this promise is already rejected');

        this.status = 'resolved';
        this.value = value;

        this._resolves.length && fireEvent(this);

        return this;
    };
    //促发拒绝事件
    Promise.prototype.reject = function(reason) {
        if (this.status === 'resolved') throw Error('this promise is already resolved');

        this.status = 'rejected';
        this.reason = reason;

        this._rejects.length && fireEvent(this);

        return this;
    };

    // promise.then(undefined, reject)
    Promise.prototype.catch = function(reject) {
        return this.then(void 0, reject);
    };

    Promise.cast = function(arg) {
        var p = new Promise();

        if (isPromise(arg)) return clonePromise(p, arg);
        else return Promise.resolve(arg);
    };

    // 返回一个已经被解决的promise
    Promise.resolve = function(arg) {
        var p = new Promise();

        return p.resolve(arg);
    };

    // 返回一个已经被拒绝的promise
    Promise.reject = function(reason) {
        var p = new Promise();

        return p.reject(reason);
    };

    // 接受多个promise
    // 如果所有promise都是resolved，则返回一个resolved的promise;
    // 只要有一个拒绝，返回一个rejected的promise;
    Promise.all = function(promises) {
        var len = promises.length;
        var promise = new Promise();
        var result = [];
        var resolved = 0;
        var rejectd = false;

        each(promises, function(p, i) {
            p.then(function(value) {
                result[i] = value;
                if (++resolved === len && !rejectd) promise.resolve(result);
            }, function(reason) {
                rejectd = true;
                promise.reject(reason);
            });
            if (rejectd) {
                return false;
            }
        });

        return promise;
    };

    // 接受多个promise
    // 只要有一个实例率先改变状态，promise的状态就跟着改变,那个率先改变的Promise实例的返回值，就传递给promise的回调函数。
    Promise.race = function(promises) {
        var promise = new Promise();
        var called;

        each(promises, function(p, i) {
            p.then(function(value) {
                if (!called) {
                    promise.resolve(value);
                    called = true;
                }
            }, function(reason) {
                if (!called) {
                    promise.reject(reason);
                    called = true;
                }
            });

            if (called) {
                return false;
            }
        });

        return promise;
    };



    function resolveRelation(promise, result) {
        if (isPromise(result)) return clonePromise(promise, result);
        else return promise.resolve(result);
    };

    //如果result也是promise,要把result赋予next
    function clonePromise(promise1, promise2) {
        var status = promise2.status;

        if ('pending' === status) return promise2.then(promise1.resolve, promise1.reject);
        if ('resolved' === status) return promise1.resolve(promise2.value);
        if ('rejected' === status) return promise1.reject(promise2.reason);
    };

    function fireEvent(promise) {
        var status = promise.status;
        var listeners = promise[status === 'resolved' ? '_resolves' : '_rejects'];
        var arg = promise[status === 'resolved' ? 'value' : 'reason'];
        var fn;
        var result;

        while (fn = queue.shift()) {
            result = fn.call(promise, arg);
            resolveRelation(promise._next, result);
        }

        return promise;
    };

    function isFn(fn) {
        return 'function' === type(fn);
    };

    function isStr(str) {
        return 'string' === type(str);
    };

    function type(obj) {
        var o = {};
        return o.toString.call(obj).replace(/\[object (\w+)\]/, '$1').toLowerCase();
    };

    function isPromise(obj) {
        return obj instanceof Promise;
    };

    function each(arr, callback) {
        var len = arr.length;
        for (var i = 0; i < len; i++) {
            var x = callback(arr[i], i, arr);
            if (x === false) {
                return;
            }
        }
    };

    return Promise;
});